import { render, staticRenderFns } from "./ResultHeaderTab.vue?vue&type=template&id=5f86bc30&scoped=true"
import script from "./ResultHeaderTab.vue?vue&type=script&lang=js"
export * from "./ResultHeaderTab.vue?vue&type=script&lang=js"
import style0 from "./ResultHeaderTab.vue?vue&type=style&index=0&id=5f86bc30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f86bc30",
  null
  
)

export default component.exports