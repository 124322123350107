var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "scroll-container flex-nowrap gap-2 overflow-auto pb-75",
    attrs: {
      "id": "result-header",
      "no-gutters": ""
    }
  }, _vm._l(_vm.getSearchPayloadArray, function (itinerary, itineraryIndex) {
    return _c('b-col', {
      key: itineraryIndex
    }, [_c('BButton', {
      staticClass: "pt-50 pb-25 px-md-1 d-flex align-items-center text-nowrap bg-white",
      attrs: {
        "variant": _vm.tabIndex === itineraryIndex ? 'success' : 'outline-success',
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleSetTabIndex(itineraryIndex);
        }
      }
    }, [!['xs'].includes(_vm.appBreakPoint) ? _c('div', {
      class: "fw-700 d-flex text-nowrap mr-1 align-items-center\n        ".concat(_vm.isMobileView ? 'font-medium-1' : 'font-medium-4', "\n        ")
    }, [_c('IAmIcon', {
      staticClass: "mr-25",
      attrs: {
        "icon": itineraryIndex === 0 ? 'trainGo' : 'trainBack',
        "size": _vm.isMobileView ? '20' : '28'
      }
    }), _c('span', {
      staticClass: "pt-25"
    }, [_vm._v(" " + _vm._s(itineraryIndex === 0 ? _vm.$t('train.search.result.outbound') : _vm.$t('train.search.result.inbound')) + " ")])], 1) : _vm._e(), _c('div', [_c('div', {
      staticClass: "fw-700 font-medium-2 mb-50 text-left"
    }, [_vm._v(" " + _vm._s(_vm.getAirportName(itinerary.departure)) + " - " + _vm._s(_vm.getAirportName(itinerary.arrival)) + " ")]), _c('div', {
      staticClass: "text-left"
    }, [_vm._v(" " + _vm._s(_vm.$t('train.search.result.departureDate')) + " " + _vm._s(_vm.convertISODateTime(itinerary.departDate).dayAndMonth) + " ")])])])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }